/* -===== ACCORDION =====- */

$(document).on("click",".accordion_headline",function(){
    var state = $(this).closest('.accordion_wrapper').attr("data-state");
    var group = $(this).closest('.accordion_wrapper').attr("data-group");
    var maxHeight = $(this).closest('.accordion_wrapper').children(".accordion_content_wrapper").eq(0)[0].scrollHeight;

    if (state === "open") {

        $(this).closest('.accordion_wrapper').children(".accordion_content_wrapper").eq(0).css("max-height","0px");
        $(this).closest('.accordion_wrapper').attr("data-state","closed");

    } else if (state === "closed") {
        //close others of group, open this
        $(".accordion_wrapper").each(function(){
            if ($(this).closest('.accordion_wrapper').attr("data-group") == group) {
                $(this).closest('.accordion_wrapper').children(".accordion_content_wrapper").eq(0).css("max-height","0px");
                $(this).closest('.accordion_wrapper').attr("data-state","closed");
            }
        });
        $(this).closest('.accordion_wrapper').children(".accordion_content_wrapper").eq(0).css("max-height",maxHeight + "px");
        $(this).closest('.accordion_wrapper').attr("data-state","open");
    }
}) ;
