$( document ).ready(function() {

    var addToWishlist = function () {
        var button = $(this);
        var href = button.attr('href');

        if (href !== undefined) {
            $.ajax({
                url: href,
                cache: false,
            });
        }
        return false;
    };

    $(document).on("click", ".add-to-wishlist", addToWishlist );

});
