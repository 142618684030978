$(document).ready(function() {
    // Detect if flash message (bootstrap alert) is visible and closes it if it is
    // $("div#flash-messages").ready(function() {
    //     setTimeout(function() {
    //         $("div#flash-messages").fadeOut("slow");
    //     }, 7000);
    // });

    // border bottom (download list)
    $('.files').last().addClass('border-bottom-red');


    $('.different-billing-address input[type="checkbox"]').change(function () {
        $('.holder-form').toggleClass('hidden');
    });

    $('.different-shipping-address input[type="checkbox"]').change(function () {
        if ($(this).is(':checked')) {
            $('.holder-form').show();
        } else {
            $('.holder-form').hide();
        }
    });


    // Enabled Jquery Tooltips
    $( 'i, input, span, button, a, s' ).tooltip( {
        html: true
    });

    // move element after cart icon
    $( ' #header-cart-count' ).insertAfter( '#header-links a .fa-shopping-cart, #header-links a .fa-shopping-bag')
});

