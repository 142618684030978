var Flickity = require('flickity');
require('flickity-as-nav-for');
require('flickity-imagesloaded');

var galleryElement = document.querySelector('.carousel');

var flkty = false;
if (galleryElement) {
    var options = galleryElement.getAttribute('data-flickity');
    options = JSON.parse(options);

    flkty = new Flickity( '.carousel', options);

    window.addEventListener("resize", function() {
        flkty.resize();
    });
}

module.exports = {
    flkty: flkty
};

$('.carousel-cell').on('mousemove', e => {
    var el = e.currentTarget;
    $('img', el).css(`transform-origin`, `${e.offsetX * 100 / $(el).width()}% ${e.offsetY * 100 / $(el).height()}%`)
});
