const Slider = require('./slider');

$(document).ready(function() { 
    if (Slider.flkty) {
        Slider.flkty.reloadCells();
        Slider.flkty.resize();
        Slider.flkty.reposition();
    }

    // Enable autosuggest
    $( ".search-query" ).ixautosuggest();
});
