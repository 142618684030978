const changeLangsInRow = function(target, lang) {
    const id = lang ? $('[value="${lang}"]', target).data('document') : $(':selected', target).data('document');
    const selectedLang = lang ? $('[value="${lang}"]', target).data('lang') : $(':selected', target).data('lang');
    const currentRow = $('[data-row="' + id + '"]');
    const baseImageUrl = $('.image-preview', currentRow).attr('src').split('Dokument')[0].replace(/\/$/, "");
    const oldDownloadUrl = $('.direct-download-from-select', currentRow).attr('href').split('/');
    oldDownloadUrl[oldDownloadUrl.length - 1] = selectedLang.id;
    const newDownloadUrl = oldDownloadUrl.join('/');
    const fileSize = lang ? $('[value="${lang}"]', event.currentTarget).data('filesize') : $(':selected', event.currentTarget).data('filesize');

    $('.image-preview', currentRow).attr('src', baseImageUrl + selectedLang.property__preview_uri__string);
    $('.image-zoom', currentRow).attr('src', baseImageUrl + selectedLang.property__zoom_uri__string);
    $('.file-title-text', currentRow).text(selectedLang.name);
    $('.file-category-text', currentRow).text(selectedLang.property__category__string);
    $('.file-type-text', currentRow).text(selectedLang.control__file_type__string);
    $('.file-size-text', currentRow).text(fileSize);
    $('.direct-download-from-select', currentRow).attr('href', newDownloadUrl);
};

const initEvents = function() {
    // display language-specific info after changing the global language
    $('.download-center #preselected_language').change( function(e) {

        $('select.language-selector-row').each( function(i, el) {
            changeLangsInRow(el);
        });

        // save the selected language to apply on next ajax pages
        currentLang = $(e.currentTarget).val();

    });

    // display language-specific information after selecting the language of the download object
    $('select.language-selector-row').change( function(event) {
        changeLangsInRow(event.currentTarget);
    });

    // privacy-policy-modal
    $('.direct-download-from-select').click( function(e) {
        if (!('localStorage' in window) || localStorage.getItem('privacyPolicyStatus') !== 'agree') {
            e.preventDefault();

            $('#privacy-policy-dialog').modal('toggle');
            $('.accept-privacy-policy').click( function (e) {
                localStorage.setItem('privacyPolicyStatus', 'agree');
                $('#privacy-policy-dialog').modal('toggle');

                e.currentTarget.tagName === 'BUTTON'
                    ? $(e.currentTarget).parents('form').submit()
                    : window.location = $(e.currentTarget).attr('href');
            })
        }
    })

};

// get value of query parameter
const getQueryParam = function(name) {
    const results = new RegExp('[\?&]${name}=([^&#]*)').exec(window.location.search);
    return (results !== null) ? results[1] || 0 : false;
};


let currentLang = '';
let currentPage = getQueryParam('page_downloads');

$(document).ready(function() {

    // initialization of functions that should be available after changing pages
    initEvents();

    $('.search-page').bind('DOMSubtreeModified', function() {
        if (currentPage != getQueryParam('page_downloads')) {
            currentPage = getQueryParam('page_downloads');
            if (currentLang != $('.download-center #preselected_language').val()) {

                // set the previously selected language in the main selector
                $( '.download-center #preselected_language' ).selectpicker( { selectedTextFormat: 'values' } );
                $( '.download-center #preselected_language' ).selectpicker( 'val',  currentLang);
                $( '.selectpicker' ).selectpicker( 'refresh' );

                // set the previously selected language for the rest of the selectors
                $('select.language-selector-row').each( function(i, el) {
                    $( el ).selectpicker( { selectedTextFormat: 'values' } );
                    $( el ).selectpicker( 'val',  currentLang);
                    $( el ).selectpicker( 'refresh' );
                    changeLangsInRow(el, currentLang)
                })

            }
            initEvents();
        }
    })
});
