const CartLoader = function(_options)
{
    var object = this;

    var options = {

        //cart_id: 1, // Cart ID must be null be default
        debug: false,
        urls: {
            cart:       '/cart',
            overview:   '/cart/overview',
            count:      '/cart/count',
            add:        '/cart/add',
            update:     '/cart/update',
            checkout:    '/checkout/address',
            proceed:       '/cart/proceed',
            coupon:      '/cart/coupon'
        },
        messages: {
            are_you_sure:   'Are you sure?'
        },
        element: {

            // count
            count:                 'cart-count',

            // list
            list:                  'cart-list',
            list_line:             'cart-list-line',
            list_line_item_prefix: 'cart-list-line-',
            list_line_remove:      'cart-list-line-remove',

            // total
            total:                 'cart-total',
            total_prefix:          'cart-total-',

            // utils
            update:                'cart-update',
            submit:                'cart-submit',

            // add
            add_item:              'cart-add-item',
            add_item_quantity:     'cart-add-item-quantity',

            //cupone
            coupon:                 'cart-coupon',
            coupon_input:           'cart-coupon-input'
        }
    };

    /**
     * Executes other initiators async
     */
    var init = function()
    {
        // null if no cart created yet
        //if (options.cart_id != null) {
            setTimeout(function() {
                initBadge();
            }, 0);
            setTimeout(function() {
                initCart();
            }, 0);
            setTimeout(function() {
                initAdd();
            }, 0);
            setTimeout(function() {
                initCoupon();
            }, 0);
        //}
    };

    /**
     * Initiates badge.
     *
     * Badge is an element which displays cart total items count.
     */
    var initBadge = function()
    {
        var els = $('.' + options.element.count);

        if (els.length <= 0) {
            return;
        }

        var url = options.urls.count;

        callApi(
            url,
            function (data) {

                var count = data['count'];

                els.each(function(i, el) {
                    $(el).show();
                    $(el).text(count);

                    if (count === 0) {
                        $(el).hide();
                    }
                });
            },
            'POST'
        );
    };

    /**
     * Initiates cart.
     *
     * Cart is an element with list of items to be purchased and
     * block with total price calculation.
     */
    var initCart = function()
    {
        var list_els = $('.' + options.element.list);
        var total_els = $('.' + options.element.total);

        if (list_els.length <= 0 && total_els.length <= 0) {
            return;
        }

        var url = options.urls.overview;

        callApi(
            url,
            function (data) {

                var items = data['overview']['items'];
                var total = data['overview']['total'];

                list_els.each(function(i, el) {
                    applyItems(el, items);
                });

                total_els.each(function(i, el) {
                    applyTotal(el, total);
                });

                updateButton();
                submitButton();
            },
            'POST'
        );
    };

    /**
     * Searches for line template in cart scope, stores template inside
     * element and reuses it on each extra call. Template is removed from DOM after found.
     *
     * @param list_element
     */
    var prepareListElement = function(list_element)
    {
        if (typeof list_element.template === 'undefined' || list_element.template == null) {
            var template = $(list_element).find('.' + options.element.list_line);

            if (template.length <= 0) {
                throw new Error('No line template provided!');
            }

            list_element.template = template.first();
        }

        $(list_element).find('.' + options.element.list_line).remove();
    };

    /**
     * Applies items one by one to template stored inside list element
     * and adds items lines into cart scope.
     *
     * @param list_element
     * @param items
     */
    var applyItems = function(list_element, items)
    {
        prepareListElement(list_element);

        $.each(items, function(i, item) {
            applyItem(list_element, item);
        });
    };

    /**
     * Applies one item to template and adds compiled template to cart scope.
     *
     * Element should have < list_line_item_prefix + key > class
     *
     * @param list_element
     * @param item
     */
    var applyItem = function(list_element, item)
    {
        var line = $(list_element.template).clone();

        $.each(item, function(key, value) {
            var item_element_class = '.' + options.element.list_line_item_prefix + key;
            var item_element = $(line).find(item_element_class);

            if (item_element.is('span') || item_element.is('div')) {
                $(item_element).text(value);
            } else if (item_element.is('input')) {
                $(item_element).val(value);
            } else if (item_element.is('img')) {
                $(item_element).attr('src', value);
            } else {
                throw new Error('Not supported type "' + item_element.prop('tagName') + '" for element "' + item_element_class + '"!');
            }

            //handle hidden inputs
            if (item_element.is('input')) {
                $(item_element).val(value);
            }
        });

        var item_remove = $(line).find('.' + options.element.list_line_remove);

        if (item_remove.length > 0) {
            $(item_remove).each(function (i, e) {
                $(e).off();
                $(e).on('click', function (event) {
                    event.stopPropagation();
                    event.preventDefault();

                    $(line).remove();

                    // if it was last line
                    if ($('.' + options.element.list_line_remove).length === 0) {
                        $('.' +  options.element.update).trigger('click');
                    }
                });
            });


        }

        $(line).find('input').prop('disabled', false);
        $(line).show();
        $(line).attr('style','');
        $(list_element).append(line);
    };

    /**
     * Applies total to template.
     *
     * @param total_element
     * @param total
     */
    var applyTotal = function(total_element, total)
    {
        $.each(total, function(key, value) {
            var total_chunk_class = '.' + options.element.total_prefix + key;
            var total_chunk = $(total_element).find(total_chunk_class);

            if (total_chunk.length <= 0) {
                throw new Error('No place for "' + key + '" to put data to.');
            }

            $(total_chunk).text(value);
        });
    };

    /**
     * Submits data from cart inputs and reinitiates js cart object
     */
    var updateButton = function ()
    {
        var update_button = $('.' + options.element.update);

        if (update_button.length <= 0) {
            return;
        }

        $(update_button).each(function (i, e) {
            $(e).off();
            $(e).on('click', {type: 'update'}, submitData);
        });
    };

    /**
     * Submits data from cart inputs and redirects to next page
     */
    var submitButton = function ()
    {
        var submit_button = $('.' + options.element.submit);

        if (submit_button.length <= 0) {
            return;
        }

        $(submit_button).each(function (i, e) {
            $(e).off();
            $(e).on('click', {type: 'submit'}, submitData);
        });
    };

    var submitData = function (event)
    {
        event.stopPropagation();
        event.preventDefault();

        var type = event.data.type;

        var url = options.urls.update;
        var that = this;

        that._data = [];

        var line = $('.' + options.element.list_line);

        $(line).find('input').prop('disabled', true);

        $(line).each(function(i, e) {
            var data = {};
            $(e).find('input').each(function() {

                var name = $(this).attr("name");

                if (typeof data[name] === 'undefined') {
                    data[name] = [];
                }

                data[name] = $(this).val();

            });

            that._data.push(data);
        });

        callApi(
            url,
            function (data) {
                log(data);
                if (type === 'update') {

                    if (data.payload.itemsCount === 0) {
                        window.location.href = options.urls.cart;
                    }
                    init();
                }
                if (type === 'submit') {
                    window.location.href = options.urls.proceed;
                }
            },
            'POST',
            {payload: that._data, type : type}
        );
    };

    /**
     * Initiates add item buttons on page
     */
    var initAdd = function ()
    {
        $(document).on('click', '.' + options.element.add_item, submitAdd);
    };

    var initCoupon = function()
    {
        $(document).on('click', '.' + options.element.coupon, function () {

            var coupone = $('.' + options.element.coupon_input).val();
            var payload = {'coupone' : coupone };

            if (coupone.length === 0) {
                return;
            }

            callApi(
                options.urls.coupon,
                function (data) {
                    if (data.status) {
                        window.location.href = options.urls.cart;
                    }
                },
                'POST',
                {payload: payload}

            );
        });
    };

    var submitAdd = function ()
    {
        var quantitySelector = '.cart-add-item-quantity';
        var button = $(this);

        if(button.data('add_item_quantity_input_selector')){
            quantitySelector = button.data('add_item_quantity_input_selector');
        }

        var quantity = $(quantitySelector).val() || 1;

        button.data('quantity', quantity);

        button.prop('disabled', true);

        //var payload = button.data();
        var url = options.urls.add;
        var payload = {
            item_code: button.data('item_code'),
            quantity: quantity
        };

        callApi(
            url,
            function (data) {

                log(data);

                button.prop('disabled', false);
                initBadge();
                initCart();
            },
            'POST',
            {payload: payload}
        );
    };


    /**
     * Calls API
     *
     * @param url
     * @param success
     * @param type
     */
    var callApi = function(url, success, type, data)
    {
        var _data = null;

        if (type == null) {
            type = 'get';
        }

        $.ajax({
            type: type,
            url: url,
            data: data,
            success: function( data ) {
                _data = data;
                success(data);
            },
            dataType: 'json'
        })
        .done(function() {
            //log( "second success" );
        })
        .fail(function() {
            log('Request to ' + url + ' was not successful.', 'error');
            log(_data, 'error');
        })
        .always(function() {
            //log( "complete" );
        });
    };


    /**
     * Internal function, wrapper over "console.log" and "console.error".
     * Produces output only if "console" object is available and "options.debug"
     * is set to "true".
     *
     * @param message
     * @param type
     */
    var log = function(message, type)
    {
        if (!options.debug) {
            return;
        }

        if (typeof console === 'undefined') {
            return;
        }

        if (typeof type === 'undefined') {
            type = 'log';
        }

        console[type](message);
    };


    /**
     * Let's start the party!
     */
    $.extend(options, _options);
    // $('.' + options.element.list_line).hide();
    init();
};

document.addEventListener('DOMContentLoaded', function() {
    var elem = document.querySelector('#cart-id');
    var cartId = elem.dataset.cartId;

    var saElem = document.querySelector('#siteaccess');
    var siteaccess = saElem.dataset.siteacess;

    new CartLoader({
        cart_id: cartId,
        debug: true,
        urls: {
            add: '/' +siteaccess + '/cart/add',
            coupon: '/' +siteaccess + '/cart/coupon',
            cart:     '/' +siteaccess + '/cart',
            overview:  '/' +siteaccess + '/cart/overview',
            count:      '/' +siteaccess + '/cart/count',
            update:     '/' +siteaccess + '/cart/update',
            checkout:    '/' +siteaccess + '/checkout/address',
            proceed:      '/' +siteaccess + '/cart/proceed',
        }
    });
});

// Set the price for selected article
$('#product-select-list').change(function() {
    var buyButton = $('button#cart-add-item');
    $('#product-price').text($('option:selected', this).data('price').toFixed(2) + " €");
    buyButton.prop('disabled', true);
    $('#cart-add-item').data('item_code', $('option:selected', this).data('id'));
    buyButton.prop('disabled', false);
});
