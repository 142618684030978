(function($) {
    var methods = {
        init: function(options) {
            methods.settings = $.extend({}, $.fn.flashNotification.defaults, options);
            methods.listenIncomingMessages();
        },

        /**
         * Listen to AJAX responses and display messages if they contain some
         */
        listenIncomingMessages: function() {
            $(document).ajaxComplete(function(event, xhr, settings) {
                if ( xhr.status == 200 && xhr.responseText != undefined) {
                    if ( xhr.responseText.indexOf( '<' ) >= 0 ) {
                        return;
                    }
                    var data = $.parseJSON( xhr.responseText );


                    if ( data.messages ) {
                        var messages = data.messages;

                        var i;

                        if ( messages.error ) {
                            for ( i = 0; i < messages.error.length; i++ ) {
                                methods.addError( messages.error[i] );
                            }
                        }

                        if ( messages.success ) {
                            for ( i = 0; i < messages.success.length; i++ ) {
                                methods.addSuccess( messages.success[i] );
                            }
                        }

                        if ( messages.info ) {
                            for ( i = 0; i < messages.info.length; i++ ) {
                                methods.addInfo( messages.info[i] );
                            }
                        }
                    }
                }
            });
        },

        addSuccess: function(message) {
            var flashMessageElt = methods.getBasicFlash(message).addClass('alert-success');

            methods.addToList(flashMessageElt);
            methods.display(flashMessageElt);
        },

        addError: function(message) {
            var flashMessageElt = methods.getBasicFlash(message).addClass('alert-error');

            methods.addToList(flashMessageElt);
            methods.display(flashMessageElt);
        },

        addCustom: function(message, type, delay) {
            var flashMessageElt = methods.getBasicFlash(message).addClass('alert-'+type);
            methods.addToList(flashMessageElt);
            flashMessageElt.show('slow');

            if(typeof delay != undefined){
                flashMessageElt
                    .delay($.fn.flashNotification.defaults.hideDelay)
                    .hide('fast', function() { $(this).remove(); } )
            }
        },

        addInfo: function(message) {
            var flashMessageElt = methods.getBasicFlash(message).addClass('alert-info');

            methods.addToList(flashMessageElt);
            methods.display(flashMessageElt);
        },

        getBasicFlash: function(message) {
            var flashMessageElt = $('<div></div>')
                    .hide()
                    .addClass('alert')
                    .append(methods.getCloseButton())
                    .append($('<div></div>').append(message))
                ;

            return flashMessageElt;
        },

        getCloseButton: function() {
            var closeButtonElt = $('<a href="#"></a>')
                .addClass('close')
                .attr("data-dismiss","alert")
                .html('&times;');

            return closeButtonElt;
        },

        addToList: function(flashMessageElt) {
            flashMessageElt.appendTo($('#flash-messages'));
        },

        display: function(flashMessageElt) {
            setTimeout(
                function() {
                    flashMessageElt
                        .show('slow')
                        .delay($.fn.flashNotification.defaults.hideDelay)
                        .hide('fast', function() { $(this).remove(); } )
                    ;
                },
                10
            );
        }
    };

    $.fn.flashNotification = function(method) {
        // Method calling logic
        if (methods[method]) {
            return methods[ method ].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || ! method) {
            return methods.init.apply(this, arguments);
        } else {
            $.error('Method ' +  method + ' does not exist on jQuery.flashNotification');
        }
    };

    $.fn.flashNotification.defaults = {
        'hideDelay'         : 3500,
        'autoHide'          : true,
        'animate'           : true
    };

    $.fn.flashNotification('init');
})(jQuery);


$( document ).ready(function() {
// Flash notification hack to go away afeter form submit
    $( '#flash-messages div.alert' ).delay( 2500 ).hide( 'fast', function () {
        $( this ).remove();
    } );
});
