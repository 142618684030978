// require js modules and the main.scss file here
//const $ = require('jquery');
//require('bootstrap');

require('../sass/main.scss');

require('./scripts/app');
require('./scripts/move-up');
require('jquery');
require('jquery-ui-sortable-npm');
require('./../../../../../../web/bundles/fosjsrouting/js/router.js');
require('./scripts/ixdemo');
require('./scripts/flashes');
require('./scripts/list');
require('./../../../../../../web/bundles/ixproductconnector/js/productfinder');
require('./scripts/actions');
require('./../../../../../../web/bundles/ixshop/cart');
require('./../../../../../../web/bundles/ixwishlist/js/wishlist');
require('./../../../../../../web/bundles/ixsearchautosuggest/js/autosuggest');
require('./scripts/bootstrap-select');
require('./../../../../../../web/bundles/productcomparison/js/comparison');
require('./modules/custom');
require('./modules/prepare');
require('./../../../../../../web/bundles/ixsearchdemo/js/download-center');
require('../../../../../../web/bundles/ixezplatformadmin/js/frontend/ixAccordion');
