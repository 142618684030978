function setAddToComparisonId(href) {
	let addToComparsionLink = document.querySelector('#add-to-comparison-link');

	if (addToComparsionLink) {
		addToComparsionLink.setAttribute('href', href);
	}
}

let child = document.querySelector('#comparsion-first-child');

if (child) {
	setAddToComparisonId(child.dataset.comparsionFirstChild);
}

let productSelectList = document.getElementById('product-select-list');

if (productSelectList) {
	productSelectList.addEventListener('change', function (e) {
		let value = e.target.value;

		for (let option of document.querySelectorAll('#product-select-list option')) {
			if (option.getAttribute('value') == value) {
				setAddToComparisonId(option.getAttribute('data-master-id'));
			}
		}
	});
}

function initThumbnailActions(thumbnailsContainer) {
	document.querySelectorAll(`${thumbnailsContainer} .comparable-product-thumbnail-container`).forEach(function (container) {
		container.addEventListener('drop', function (e) {
			if (e.preventDefault) { e.preventDefault(); }
			if (e.stopPropagation) { e.stopPropagation(); }

			var index = this.getAttribute('data-index');
			var documentId = e.dataTransfer.getData("text/plain");

			document.querySelectorAll('.comparable-product').forEach(function (element) {
				element.setAttribute('draggable', 'false');
			});

			fetch(this.getAttribute('data-change-position-path'), {
				method: 'post',
				body: JSON.stringify({
					documentId: documentId,
					index: index
				})
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				if (data['success']) {
					window.location.reload();
				}
			});

			return false;
		});
		container.addEventListener('dragover', function (e) {
			document.querySelectorAll('.comparable-product-drop-here').forEach(function (el) {
				el.style.display = 'none';
			});

			this.querySelector('.comparable-product-drop-here').style.display = 'block';
			e.preventDefault();
		});
	});

	document.querySelectorAll(`${thumbnailsContainer} .comparable-product`).forEach(function (element) {
		element.addEventListener('dragstart', function (e) {
			this.classList.add('comparable-product-dragging');
			e.dataTransfer.setData("text/plain", e.target.getAttribute('data-document-id'));
		});
		element.addEventListener('dragend', function (e) {
			e.preventDefault();

			this.classList.remove('comparable-product-dragging');

			document.querySelectorAll('.comparable-product-drop-here').forEach(function (el) {
				el.style.display = 'none';
			});
		});
	});

	document.querySelectorAll(`${thumbnailsContainer} .comparison-cart-add`).forEach(function (el) {
		el.addEventListener('click', function (e) {
			e.preventDefault();

			var formData = new FormData();
			formData.append('payload[item_code]', this.getAttribute('data-document-id'));
			formData.append('payload[quantity]', '1');

			$.ajax({
				url: this.getAttribute('data-cart-add-path'),
				context: document.body,
				data: formData,
				processData: false,
				contentType: false,
				type: 'POST'
			}).done(function () {
				console.log('Product added to the cart.');
			});

		});
	});

	document.querySelectorAll(`${thumbnailsContainer} .choose-as-original`).forEach(function (el) {
		el.addEventListener('click', function (e) {
			// unselect others
			$('input.choose-as-original').prop('checked', false);
			$(this).prop('checked', true);

			if (this.value) {
				var documentId = this.getAttribute('data-document-id');
				var index = 0;

				fetch(this.getAttribute('data-change-position-path'), {
					method: 'post',
					body: JSON.stringify({
						documentId: documentId,
						index: index
					})
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					if (data['success']) {
						window.location.reload();
					}
				});
			}
		});
	});
}

function initComparisonPreview() {
	var comparisonPreview = document.querySelector('#comparison-preview');
	var comparisonPreviewToggle = document.querySelector('#comparison-preview-toggle');
	var previewContent = document.querySelector('#comparison-preview-content .products-container').innerHTML;
	var comparisonPreviewVisible = false;

	const previewUrl = comparisonPreview.getAttribute('data-preview-path');

	comparisonPreviewToggle.addEventListener('mouseover', function () {

		if (comparisonPreviewVisible || previewContent.trim() !== "") {
			return false;
		} else {
			comparisonPreviewVisible = true;

			fetchPreview(comparisonPreview.getAttribute('data-preview-path'));
		}

	});

	// fetch by page loads by default
	fetchPreview(previewUrl);
}

var refetchPreview = function () { };

function fetchPreview(url) {
	fetch(url, {
		credentials: 'same-origin'
	})
		.then(function (resp) {
			return resp.text();
		}).
		then(function (html) {
			var container = document.querySelector('#comparison-preview-content .products-container');

			container.innerHTML = html;

			initThumbnailActions("#comparison-preview-content");

			$('.comparison-preview-loading').hide();
			$('.comparison-preview-list').show();

			initWishlistSelect($(container));


			refetchPreview = function () {
				$('.comparison-preview-list').hide();
				$('.comparison-preview-loading').show();

				fetchPreview(url)
			};
		});
}


function initDifferencesSwitch() {
	var showEquals = true;
	var differencesSwitch = document.getElementById('comparison-differences-switch');
	if (differencesSwitch) {
		differencesSwitch.addEventListener('click', function () {
			if (showEquals) {
				for (var entry of document.querySelectorAll('.comparison-entry-equal-only')) {
					entry.style.display = 'none';
					differencesSwitch.innerHTML = differencesSwitch.getAttribute('data-label-showall');
				}

				showEquals = false;
			} else {
				for (var entry of document.querySelectorAll('.comparison-entry-equal-only')) {
					entry.style.display = 'flex';
					differencesSwitch.innerHTML = differencesSwitch.getAttribute('data-label-hideequal');
				}

				showEquals = true;
			}
		});
	}
}

function initComparisonAccordion() {
	document.querySelectorAll('.collapse-button.comparison-collapse').forEach(function (button) {
		button.addEventListener('click', function () {
			let index = this.getAttribute('data-index');

			if (this.getAttribute('data-collapsed') === 'false') {
				this.setAttribute('data-collapsed', 'true');

				document.getElementById('collapse-arrow-up-' + index).style.display = 'none';
				document.getElementById('collapse-arrow-down-' + index).style.display = 'inline';

			} else {
				this.setAttribute('data-collapsed', 'false');

				document.getElementById('collapse-arrow-up-' + index).style.display = 'inline';
				document.getElementById('collapse-arrow-down-' + index).style.display = 'none';
			}
		});
	});
}

function initComparisonClearList() {
	var comparisonClearList = document.getElementById('comparison-clear-list');
	if (comparisonClearList) {
		comparisonClearList.addEventListener('click', function (e) {
			e.preventDefault();

			fetch(this.getAttribute('data-path'))
				.then(function () {
					window.location.reload();
				});
		});
	}
}

function initComparisonLink() {
	const addToComparisonLink = '#add-to-comparison-link, .add-to-comparison-link' ;

    document.querySelectorAll(addToComparisonLink).forEach(function (element) {
        element.addEventListener('click', function (e) {
            e.preventDefault();

            $.ajax({
                url: this.getAttribute('href')
            }).done(function () {
                refetchPreview();
            });
        })
    });
}

function initPreviewDeletion() {
	$('#comparison-preview-content').on('click', '.comparison-delete', function (e) {
		e.preventDefault();

		const href = this.getAttribute('href');

		$.ajax({
			url: href
		}).done(function () {
			refetchPreview();
		});
	});
}

function initDetailsDeletion() {
	$('#comparison-details-products-list').on('click', '.comparison-delete', function (e) {
		e.preventDefault();

		const href = this.getAttribute('href');

		$.ajax({
			url: href
		}).done(function () {
			window.location.reload();
		});
	});
}

function initExportDropdownMenu() {
	var exportHref;
	const $submitButton = $('<a class="btn-close">Export</a>');

	$('#comparison-export-action').selectpicker({
		iconBase: 'far',
		tickIcon: 'fa-dot-circle',
		showTick: true,
		showIcon: true
	})
		.on('loaded.bs.select', function (e) {
			const $dropdownUl = $('.comparison-export-dropdown ul');
			$dropdownUl.after($submitButton);
		})
		.on('changed.bs.select', function (e) {
			exportHref = $(this).selectpicker('val');

			$submitButton.attr('href', exportHref);
		});
}

function initProductThumbnailOptions() {
	$(document).on('click', '.comparison-add-to-wishlist', function (e) {
		e.preventDefault();

		const $selectContainer = $('.options-tab-wishlist', $(this).parents('.options-tab-container'));

		$selectContainer.show();
	});
}

function enableWishlistSelects(container) {
	var wishlistSelect = $('.comparison-add-to-wishlist-select', container);

	if (wishlistSelect.length === 0) {
		return;
	}

	function loadWishlist() {
		const url = wishlistSelect.attr('data-wishlist-path')

		// don't make a request if url is undefined
		if (url == undefined) return;

		// Function to update select via ajax
		$.get(url, {}, true)
			.done(function (data) {
				var options = '';
				var text = wishlistSelect.data('option-trans');

				Object.values(data).map(({ id, items, name }) => {
					options = options + `<option value="${id}" data-subtext="${items ? items.length : 0} ${text}">${name}</option>\n`;
					return options;
				});

				wishlistSelect
					.find('option')
					.remove()
					.end()
					.append(options)
					.selectpicker('refresh');
			});
	}

	wishlistSelect
		.on('changed.bs.select', function (e, clickedIndex, isSelected, previousValue) {
			const item = $(this);
			let itemId = $(this).data('item-src');
			if (itemId.startsWith('#') || itemId.startsWith('.')) {
				itemId = $(itemId + ' option:selected').val();
			}
			const wishlistId = item.val();
			const quantity = 1;

			$.get(Routing.generate('wishlist_add_related_object', {
				'id': itemId,
				'wishlistId': wishlistId,
				'quantity': quantity
			}, true))
				.done(function () {
					// wishlistSelect.refreshSelect();
					loadWishlist();

					const $selectContainer = $('.options-tab-wishlist', $(item).parents('.options-tab-container'));

					$selectContainer.hide();
				})

		});

	loadWishlist();
}

function initWishlistSelect(container) {
	enableWishlistSelects(container);
}

initComparisonPreview();
initThumbnailActions("#comparison-details-products-list");
initDifferencesSwitch();
initComparisonClearList();
initComparisonAccordion();
initComparisonLink();
initPreviewDeletion();
initExportDropdownMenu();
initProductThumbnailOptions();
initWishlistSelect();
