function getQueryParam(param, str) {
    var rx = new RegExp("[?&]" + param + "=([^&]+).*$");
    var returnVal = str.match(rx);
    return returnVal === null ? "" : returnVal[1];
}

$( document ).ready( function () {

    // Log to native console if possible, alert otherwise
    if ( !window.console ) window.console = {};
    if ( !window.console.log ) window.console.log = function () {
    };

    // Namespacing
    var wishlistNs = {};

    wishlistNs.searchParams={};
    location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi,function(s,k,v){wishlistNs.searchParams[k]=v});

    // Field definitions
    wishlistNs.formName = 'form[name="wishlist"]';
    wishlistNs.form = $( wishlistNs.formName );
    wishlistNs.action = $( '#wishlist-action' );
    wishlistNs.dropdownToggle = $( '.dropdown-toggle[data-id=wishlist-action]' );
    wishlistNs.addToWishlistSelect = $( 'select.add-to-wishlist-select' );

    // Single wishlist fix
    if ( wishlistNs.searchParams.id == undefined ) {
        if ( wishlistNs.form.length > 0 ) {
            var action = wishlistNs.form.attr('action');
            if (action) {
                var id = getQueryParam('id', action);
                if ( id ) {
                    wishlistNs.searchParams.id = id;
                }
            }
        }
    }

    wishlistNs.getShowNewWishlist = function () {
        if ( typeof $( '#ix-s-n-w' ).val() != 'undefined' ) {
            return $( '#ix-s-n-w' ).val();
        } else {
            return ix_phpvars.wishlistCreateFromSelect;
        }
    };

    // Functions
    // ==============

    // Function to refresh select via ajax
    wishlistNs.refreshSelect = function () {
        if ( wishlistNs.addToWishlistSelect.length ) {

            // Function to update select via ajax
            $.get( Routing.generate( 'wishlist_get_all', {}, true ) )
                .done( function ( data ) {
                    if ( data.constructor.name === 'Object' ) {

                        // Get options from json
                        var options = [];
                        var text = wishlistNs.addToWishlistSelect.data( 'option-trans' );

                        // create and sort object
                        let sortable = Object.values(data).sort((a, b) =>
                            a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0
                        )

                        sortable.map( ({id, items, name}) => {
                            return options.push(`<option value="${id}" data-subtext="${items.length} ${text}"> ${name} </option>`)
                        })

                        if (wishlistNs.getShowNewWishlist()) {
                            options.push(`<option data-divider="true"></option><option value="create_new_wishlist"> ${ix_phpvars.translation.msg.wishlist_select_add_to_new} </option>`)
                        }

                        // Rebuild select
                        if ( options ) {
                            wishlistNs.addToWishlistSelect
                                .find( 'option' )
                                .remove()
                                .end()
                                .append( options.join('\n') )
                                .selectpicker( 'refresh' );
                        }
                    }
                } );
        }
    };

    // Shows load spinner
    wishlistNs.showLoaderSpinner = function () {
        $( "#loaderSpinner" ).modal( {
            backdrop: "static", //remove ability to close modal with click
            keyboard: false, //remove option to close with keyboard
            show: true //Display loader!
        } );
    };

    // Stop loader spinner
    wishlistNs.stopLoaderSpinner = function () {
        $( "#loaderSpinner" ).modal( "hide" );
    };

    // Init function
    wishlistNs.init = function ( event, ui ) {

        // Standard selectpicker without any settings
        $( '.bootstrap_select' ).selectpicker();

        // All events
        // ==============

        // Wishlist overview
        $('.btn-delete-wishlist').click(function () {

            var url = $(this).attr('href');

            $('#deleteModalLink').attr('href', url);
            $('#deleteModal').modal('show');

            return false;
        });

        // Drag/Drop sorting
        $( ".sortable" ).sortable( {
            handle: '.dragger',
            placeholder: 'drop-placeholder items col-sm-12',
            update: function ( event, ui ) {
                var id =$(ui.item).find('.wishlist-item-dragger').attr('rel');

                if ( id ) {
                    $.ajax( Routing.generate( 'wishlist_items_sort', { 'id': id, 'position': ui.item.index() }, true ));
                }
            }
        } );

        // Change quantity
        $( '[id$="_quantity"]' )
            .on( 'change', function () {
                $( wishlistNs.formName + ' .button-main' ).removeAttr( 'disabled' );
                var changed = true;
            } )
            .focusout( function () {
                var form = wishlistNs.form;

                if ( $( form ).is( ':visible' ) ) {
                    $.post(
                        Routing.generate( 'wishlist_items_update_quantity', {'id':wishlistNs.searchParams.id}, true ),
                        $( form ).serialize()
                    );
                }
            } );

        // Update note field
        $( '[id$="_note"]' )
            .change( function () {
                var form = wishlistNs.form;

                if ( $( form ).is( ':visible' ) ) {
                    $.post(
                        Routing.generate( 'wishlist_items_update_note', {'id':wishlistNs.searchParams.id}, true ),
                        $( form ).serialize()
                    );
                }
            } );

        // Toggle note field
        $( '.wishlist-item-note label' ).click( function () {
            labelID = $( this ).attr( 'for' );
            $( '#' + labelID ).slideToggle();
        } );

        // Select to delete
        $( ".wishlist .wishlist-items input[type='checkbox']" ).click( function () {
            $( wishlistNs.formName + ' .button-main' ).removeAttr( 'disabled' );
        } );

        // Wishlist rename
        $( "#wishlist_rename" ).unbind( 'click' ).click( function () {
            $( '#wishlist_name' ).removeAttr( 'disabled' ).css( 'position', 'absolute' ).toggle();
            $( wishlistNs.formName + ' button#wishlist_submit, #wishlist_rename_cancel' ).removeClass( 'hidden' );

            $( this ).attr( 'disabled', 'disabled' );
            return false;
        } );


        $("#wishlist_rename_cancel").click(function () {
            $( '#wishlist_name' ).attr('disabled', 'disabled').css('display', 'none');
            $( wishlistNs.formName + ' button#wishlist_submit, #wishlist_rename_cancel' ).addClass(' hidden ');

            $( "#wishlist_rename" ).removeAttr('disabled')
            return false;
        })

        // Wishlist Action Radio Select Picker
        var radiopickerInited = false;
        // Initialize radio button selectpicker
        $( '.radio-picker' ).selectpicker( {
            iconBase: 'far',
            tickIcon: 'fa-dot-circle',
            showTick: true,
            showIcon: true
        } )
        // After select is initialized
            .on( 'loaded.bs.select', function ( e ) {
                if ( !radiopickerInited ) {
                    radiopickerInited = true;
                    // Add close button in dropdown
                    $.each( $( '.radio-picker button .bs-caret' ), function ( i, el ) {
                        var $form_row = $( el ).closest( ".form-group" );
                        var $btn_close = $( "<a>", {
                            href: "#",
                            class: "btn-close",
                            text: ix_phpvars.translation.msg.facet_close || "Schließen",
                            "data-close-text": ix_phpvars.translation.msg.facet_close || "Schließen",
                            "data-search-text": ix_phpvars.translation.msg.facet_apply || "Speichern",
                            "data-input-id": $form_row.find( "select" ).attr( "id" )
                        } );
                        // close button click
                        $btn_close.click( function ( event ) {
                            event.stopPropagation();

                            const $input = $( "#" + $( this ).data( "inputId" ) );
                            const isApplyButton = $btn_close.text() == $btn_close.data( "searchText" );
                            const actionValue = wishlistNs.action.val();

                            $input.selectpicker( 'toggle' );

                            if ( isApplyButton && actionValue === 'add_to_comparison' ) {

                                const selectedProductsToCompare = $('[id*="wishlist_items"]:checked');
                                const idsOfSelectedProducts = Array.from(selectedProductsToCompare).map(item => item.dataset.compareId);
                                const closeButton = $( '.dropdown-menu.open .btn-close' );
                                const urlAddMultipleToCompare =  $('[value="add_to_comparison"]', wishlistNs.action).data('url');

                                $.ajax({
                                    url: urlAddMultipleToCompare,
                                    type: "POST",
                                    contentType: 'application/json',
                                    dataType: 'json',
                                    data: JSON.stringify({
                                        "products": idsOfSelectedProducts
                                    }),
                                })

                                // reset acttions dropdown
                                closeButton.removeClass( "search" ).text( closeButton.data( "closeText" ) );
                                wishlistNs.action.val("").selectpicker( 'refresh' );

                            } else if ( isApplyButton ) {

                                $( this ).parents( 'form:first' ).submit();

                            }

                            return false;
                        } );
                        $form_row.find( 'ul.dropdown-menu' ).after( $btn_close );
                    } );
                }
            } )
            // On select a value
            .on( 'changed.bs.select', function () {
                // Rename close button
                var $closeButtons = $( '.dropdown-menu.open .btn-close' );
                $closeButtons.addClass( "search" ).text( $closeButtons.data( "searchText" ) );
            } );


        // No automatic close if class "noclose" exists
        $( '.radio-picker.noclose .dropdown-menu' ).on( 'click', function ( event ) {
            event.stopPropagation();
        } );


        // Initialize selectpicker for add to wishlist select with ajax data
        wishlistNs.addToWishlistSelect
            .selectpicker( {
                selectedTextFormat: 'static',
                showTick: false
            } )
            // On select add to wishlist
            .on( 'changed.bs.select', function ( e, clickedIndex, isSelected, previousValue ) {
                var item = $( this );
                var itemId = $( this ).data( 'item-src' );
                if ( itemId.startsWith( '#' ) || itemId.startsWith( '.' ) ) {
                    itemId = $( itemId + ' option:selected' ).val();
                }
                var wishlistId = item.val();
                var quantVal = $( $( this ).data( 'item-quantity' ) ).val();
                var quantity = quantVal >= 1 ? quantVal : 1;

                let routingUrl = '';
                if ('create_new_wishlist' === wishlistId) {
                    routingUrl = Routing.generate( 'wishlist_add_to_new_wishlist', {
                        'productId': itemId,
                    }, true )
                } else {
                    routingUrl = Routing.generate( 'wishlist_add_related_object', {
                        'id': itemId,
                        'wishlistId': wishlistId,
                        'quantity': quantity
                    }, true )
                }

                $.get( routingUrl )
                // Refresh select via ajax call
                    .done( function () {
                        wishlistNs.refreshSelect();
                    } )
                    // Clear selection
                    .always( function () {
                        item.selectpicker( 'val', '' );
                        item.selectpicker( 'render' );
                    } )
            } );

        // All wishlist items checkbox
        $( '#select-all-wishlist-items' ).click( function ( event ) {
            if ( this.checked ) {
                // Iterate each checkbox
                $( '.wishlist-items div.wishlist-item-select :checkbox' ).each( function () {
                    this.checked = true;
                } );
                wishlistNs.dropdownToggle.removeAttr( 'disabled' );
            } else {
                $( '.wishlist-items div.wishlist-item-select :checkbox' ).each( function () {
                    this.checked = false;
                } );
                wishlistNs.dropdownToggle.attr( 'disabled', true );
            }
        } );

        // Activate wishlist action onlywhen an item is selected
        $( '.wishlist-item-select :checkbox' ).on( "click", function () {
            // Iterate each checkbox
            var enabled = false;
            $( '.wishlist-items  div.wishlist-item-select :checkbox' ).each( function () {
                if ( this.checked ) {
                    enabled = true;
                }
            } );
            if ( enabled ) {
                wishlistNs.action.removeAttr( 'disabled' );
                wishlistNs.dropdownToggle.removeAttr( 'disabled' );
            } else {
                wishlistNs.action.attr( 'disabled', true );
                wishlistNs.dropdownToggle.attr( 'disabled', true );
            }
        } );

        // Initialize wishlist switcher
        $( 'select[name=switch_wishlist]' ).change( function () {
            location.replace( $( this ).val() );
        } );

        // Initialize add to wishlist select
        wishlistNs.refreshSelect();

        // Hide Wishlist action by default
        var enabled = false;
        $( '.wishlist-items  div.wishlist-item-select :checkbox' ).each( function () {
            if ( this.checked ) {
                enabled = true;
            }
        } ).promise().done( () => {
            if ( enabled ) {
                wishlistNs.action.removeAttr( 'disabled' );
                wishlistNs.dropdownToggle.removeAttr( 'disabled' );
            } else {
                wishlistNs.action.attr( 'disabled', true );
                wishlistNs.dropdownToggle.attr( 'disabled', true );
            }
        });

        $(".modal.enabled").each( function ( index, element ) {
            $( this ).modal();
        } );

        // Init header counter badge
        if (typeof ix_phpvars.wishlistCount != 'undefined' && ix_phpvars.wishlistCount > 0) {
            $( ".wishlist-icon" )
                .parent()
                .after(`<div id="header-wishlist-counter" class="badge badge-counter">${ix_phpvars.wishlistCount}</div>`);
        }

    };

    wishlistNs.init();
} );
